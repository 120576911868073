import { ErrorState } from '~/components/error-boundary';
import { AnalyticsScripts } from '~/utils/analytics';
import { AuthProvider } from '~/utils/auth';
import { useRouter } from '~/utils/routing/useRouter';
import { styled } from '~/utils/styling';

const Container = styled('div', {
  width: '100%',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center'
});

const e: Error & { code?: number } = new Error('Page not found');
e.code = 404;

function Error404Page() {
  const router = useRouter();

  return (
    <>
      <AnalyticsScripts />
      <AuthProvider data={null}>
        <Container>
          <ErrorState error={e} pathname={router.asPath} />
        </Container>
      </AuthProvider>
    </>
  );
}

export default Error404Page;
